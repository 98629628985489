import RPM from '../../assets/homepageprojects/readyplayerme.jpg';
import RPM2 from '../../assets/homepageprojects/readyplayerme2.jpg';
import Cedaradashboard from '../../assets/homepageprojects/cedaradashboard.jpg';
import omatalo from '../../assets/homepageprojects/omatalo.jpg';
import omatalo2 from '../../assets/homepageprojects/omatalo2.jpg';
import documental from '../../assets/homepageprojects/documental.jpg';
import carloan from '../../assets/homepageprojects/carloan.jpg';
import superforest1 from '../../assets/homepageprojects/superforest1.jpg';
import superforest2 from '../../assets/homepageprojects/superforest2.jpg';
import superforest3 from '../../assets/homepageprojects/superforest3.jpg';
import superforest4 from '../../assets/homepageprojects/superforest4.jpg';
import credinord from '../../assets/homepageprojects/credinord.jpg';
import arvuta from '../../assets/homepageprojects/arvuta.jpg';
import creditea from '../../assets/homepageprojects/creditea.jpg';
import noortejooks from '../../assets/homepageprojects/noortejooks.jpg';
import aegarstile from '../../assets/homepageprojects/aegarstile.jpg';
import punks from '../../assets/homepageprojects/punks.jpg';
import c24 from '../../assets/homepageprojects/c24.jpg';
import contentkitchen from '../../assets/homepageprojects/contentkitchen.jpg';
import bookedit from '../../assets/homepageprojects/bookedit.jpg';
import rahapalvelu from '../../assets/homepageprojects/rahapalvelu.jpg';
import turva from '../../assets/homepageprojects/turva.jpg';
import eventmanager from '../../assets/homepageprojects/eventmanager.jpg';
import taw1 from '../../assets/homepageprojects/taw1.jpg';
import taw12 from '../../assets/homepageprojects/taw12.jpg';
import taw2 from '../../assets/homepageprojects/taw2.jpg';
import taw22 from '../../assets/homepageprojects/taw22.jpg';
import taw3 from '../../assets/homepageprojects/taw3.jpg';
import taw32 from '../../assets/homepageprojects/taw32.jpg';
import wachtlokaal from '../../assets/homepageprojects/wachtlokaal.jpg';
import tekto from '../../assets/homepageprojects/tekto.jpg';

const GiveProjectsData = [];

let pr = 1;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Ready Player Me',
  image: RPM,
  testImage: '../../assets/homepageprojects/eventmanager.jpg',
  // video: TektoVideo,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Ready Player Me',
  image: RPM2,
  testImage: '../../assets/homepageprojects/cedaradashboard.jpg',
  // video: BookedItVideo,
  sliderRow: 1,
});
pr++;
/*
GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Cedara',
  image: Cedaradashboard,
  // video: Credit24Video,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Omatalo',
  image: omatalo,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Omatalo',
  image: omatalo2,
  // video: TAWVideo,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Documental',
  image: documental,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Credit24 Carloan',
  image: carloan,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Superforest',
  image: superforest1,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Superforest',
  image: superforest2,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Superforest',
  image: superforest3,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Superforest',
  image: superforest4,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Credinord',
  image: credinord,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Arvuta',
  image: arvuta,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Creditea',
  image: creditea,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Hepa noortejooks',
  image: noortejooks,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Aegarstile',
  image: aegarstile,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'ReadyPlayerMe Punks',
  image: punks,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Credit24 Finland',
  image: c24,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Contentkitchen',
  image: contentkitchen,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Bookedit',
  image: bookedit,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Rahapalvelu',
  image: rahapalvelu,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Turva',
  image: turva,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Licklist eventmanager',
  image: eventmanager,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Tallinn Art Week',
  image: taw1,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Tallinn Art Week',
  image: taw12,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Tallinn Art Week',
  image: taw2,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Tallinn Art Week',
  image: taw22,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Tallinn Art Week',
  image: taw3,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Tallinn Art Week',
  image: taw32,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Wachtlokaal',
  image: wachtlokaal,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: 'Tekto',
  image: tekto,
  sliderRow: 1,
});
pr++;
*/
pr++;
// Content Kitchen Noar Booked it Kaspar Nisu Hepa e-pood Creditea Rahapalvelu Credit24 newsletters

export default GiveProjectsData;

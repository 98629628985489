import { gsap } from 'gsap';
import { Draggable } from 'gsap/all';
import StripItem from './StripItem';

// Images strip
class Strip {
  constructor(el, contentItems) {
    this.contentItems = contentItems;
    this.DOM = { el };

    this.DOM.strip = this.DOM.el.querySelector('.strip');
    this.items = [];
    [...this.DOM.el.querySelectorAll('.project-item')].forEach((item) =>
      this.items.push(new StripItem(item))
    );

    this.initEvents();

    /// GSAP DRaggable
    this.draggie = new Draggable.create('.draggable', {
      type: 'x',
      bounds: '#container',
      edgeResistance: 0.05,
      bounds: window,
      inertia: true,
      lockAxis: true,

      onPress(event) {
        gsap.to(event.srcElement.parentElement, {
          duration: 0.25,
          // opacity: 0.5,
          scale: 0.95,
        });
      },
      onRelease(event) {
        gsap.to(event.srcElement.parentElement, {
          // '.strip__item-link'
          duration: 0.5,
          // opacity: 1,
          scale: 1,
        });
      },

      onDragStart(event) {
        // console.log('::onDragStart');
        // console.log(event.srcElement.parentElement);

        gsap.to(event.srcElement.parentElement, {
          duration: 0.25,
          // opacity: 0.5,
          scale: 0.37,
        });
      },
      onDragEnd(event) {
        // console.log('::onDragEnd');
        // console.log(event.srcElement.parentElement);

        // if user has leaved draggable item - it won't return that item here. Then it's safe to
        // animate all back to normal- otherwise that one will remina "clicked/dragged" style
        let projectId = '';
        if (
          typeof event.srcElement.parentElement.dataset !== 'undefined' &&
          typeof event.srcElement.parentElement.dataset.projectid !==
            'undefined'
        ) {
          projectId = event.srcElement.parentElement.dataset.projectid;
        }

        gsap.to('.strip__item-link', {
          duration: 0.5,
          opacity: 1,
          scale: 1,
        });
      },
    });
  }

  initEvents() {
    for (const item of this.items) {
      item.DOM.label.addEventListener('mouseenter', (ev) => {
        // item.DOM.number.addEventListener('click', (ev) => {
        // ev.preventDefault();
        this.showItem(item);
      });
      item.DOM.label.addEventListener('mouseleave', (ev) => {
        // item.DOM.number.addEventListener('click', (ev) => {
        // ev.preventDefault();
        this.closeItem(item);
      });
    }
  }

  showItem(item) {
    // if ( this.isAnimating ) {
    //     return false;
    // }
    // if ( this.renderId ) {
    //     window.cancelAnimationFrame(this.renderId);
    //     this.renderId = undefined;
    // }
    // this.isAnimating = true;
    this.current = this.items.indexOf(item);
    const contentItem = this.contentItems[this.current];
    contentItem.DOM.el.classList.add('content__item--current');

    const currentlyHoveredProjectId = item.DOM.el.dataset.projectid;
    // console.log( 'Current project hovered:' + currentlyHoveredProjectId );

    const listAllOthers = document.querySelectorAll('.project-item');

    listAllOthers.forEach(function (domItem) {
      if (domItem.dataset.projectid != currentlyHoveredProjectId) {
        // console.log( 'All other projects IDS:' + domItem.dataset.projectid );

        domItem.classList.add('hover-shrink');

        /* 
        gsap.to( domItem, {
          duration: 0.5,
          opacity: 0.3,
          onComplete : function(){
            // console.log( 'Finished naimation' );
          }
        });
        */
      }
    });
  }

  closeItem(item) {
    const contentItem = this.contentItems[this.current];
    contentItem.DOM.el.classList.remove('content__item--current');

    const currentlyHoveredProjectId = item.DOM.el.dataset.projectid;
    // console.log( 'Current project hovered:' + currentlyHoveredProjectId );

    const listAllOthers = document.querySelectorAll('.project-item');

    listAllOthers.forEach(function (domItem) {
      // if( domItem.dataset.projectid != currentlyHoveredProjectId ){
      // console.log( 'All other projects IDS:' + domItem.dataset.projectid );

      domItem.classList.remove('hover-shrink');

      /* 
        gsap.to( domItem, {
          duration: 0.5,
          opacity: 1
        });
        */
      // }
    });

    if (this.isAnimating) {
      // console.log("LEEEEEEEEEEEVEEE is ani on")
      return false;
    }
    this.isAnimating = true;
  }
}

export default Strip;

/* eslint-disable max-classes-per-file */
import React, { useRef, useEffect } from 'react';

import { gsap } from 'gsap';

import { CSSPlugin, Draggable } from 'gsap/all';
import { StaticImage } from 'gatsby-plugin-image';
import Strip from './Strip';
import ContentItem from './ContentItem';
import GiveProjectsData from './GiveProjectsData.js';
import GiveContentTrans from "../../components/GiveContentTrans";
// gsap.registerPlugin(Draggable);
// const dependencies = [CSSPlugin, TweenLite];

export default function ProjectsSlider() {
  const dragContainer = useRef(null);
  const dragItems1 = useRef(null);
  const dragItems2 = useRef(null);

  const workPreview = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(Draggable, CSSPlugin);
    // console.clear();

    let startDragX = 0;
    let lastDragDirection = '';

    const slideDelay = 1.5;
    const slideDuration = 0.3;

    const slides = document.querySelectorAll('.slide');
    // const prevButton = document.querySelector('#prevButton');
    // const nextButton = document.querySelector('#nextButton');

    const numSlides = slides.length;

    for (let i = 0; i < numSlides; i++) {
      gsap.set(slides[i], {
        // backgroundColor: `#${((Math.random() * 0xffffff) << 0).toString(16)}`,
        xPercent: i * 100,
      });
    }

    // const timer = gsap.delayedCall(slideDelay, autoPlay);

    const animation = gsap.to(slides, {
      duration: 1,
      xPercent: `+=${numSlides * 100}`,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: gsap.utils.wrap(-100, (numSlides - 1) * 100),
      },
    });

    const proxy = document.createElement('div');
    gsap.set(proxy, { x: 0 });
    let slideAnimation = gsap.to({}, { duration: 0.1 });
    let slideWidth = 0;
    let wrapWidth = 0;
    resize();

    const draggable = new Draggable(proxy, {
      trigger: '.slides-container',
      throwProps: true,
      onPress: updateDraggable,
      onRelease: releaseDraggable,
      onDrag: updateProgress,
      allowEventDefault: true,
      onThrowUpdate: updateProgress,
      onDragEnd: centerPosition,
      snap: {
        // x: gsap.utils.snap(slideWidth),
        x(x) {
          gsap.utils.snap(slideWidth, Math.round(x / slideWidth) * slideWidth);
        },
      },
    });

    window.addEventListener('resize', resize);

    // prevButton.addEventListener('click', function () {
    //   animateSlides(1);
    // });

    // nextButton.addEventListener('click', function () {
    //   animateSlides(-1);
    // });

    function releaseDraggable(event) {
      gsap.to('.strip', {
        duration: 0.25,
        opacity: 1,
        scale: 1,
      });
      // console.log(proxy);
      if (event.pageX < startDragX) {
        lastDragDirection = 'left';
      } else if (event.pageX > startDragX) {
        lastDragDirection = 'right';
      } else {
        lastDragDirection = '';
      }

      // timer.restart(true);
      slideAnimation.kill();
      this.update();
    }

    function updateDraggable(event) {
      gsap.to('.strip', {
        duration: 0.25,
        opacity: 0.5,
        scale: 0.75,
      });
      // console.log(proxy);

      startDragX = event.pageX;

      // timer.restart(true);
      slideAnimation.kill();
      this.update();
    }

    function centerPosition(direction) {
      // console.log('center 1 2 3');
      slideAnimation.kill();

      // const x = snapX(gsap.getProperty(proxy, 'x') + direction * slideWidth);
      const x = snapX(gsap.getProperty(proxy, 'x'));

      slideAnimation = gsap.to(proxy, {
        duration: slideDuration,
        ease: 'back.out(1.1)',
        x,
        onUpdate: updateProgress,
      });

      gsap.to('.strip', {
        duration: 0.25,
        opacity: 1,
        scale: 1,
      });
    }

    function animateSlides(direction) {
      // timer.restart(true);
      slideAnimation.kill();

      const x = snapX(gsap.getProperty(proxy, 'x') + direction * slideWidth);

      slideAnimation = gsap.to(proxy, {
        duration: slideDuration,
        x,
        onUpdate: updateProgress,
      });
    }

    function autoPlay() {
      if (draggable.isPressed || draggable.isDragging || draggable.isThrowing) {
        // timer.restart(true);
      } else {
        animateSlides(-1);
      }
    }

    function updateProgress() {
      animation.progress(
        gsap.utils.wrap(0, 1, gsap.getProperty(proxy, 'x') / wrapWidth)
      );
    }

    function resize() {
      const norm = gsap.getProperty(proxy, 'x') / wrapWidth || 0;

      slideWidth = slides[0].offsetWidth;
      wrapWidth = slideWidth * numSlides;

      gsap.set(proxy, {
        x: norm * wrapWidth,
      });

      animateSlides(0);
      slideAnimation.progress(1);
    }

    function snapX(x) {
      // console.log('snapX');
      // console.log(`${slideWidth} / ${x}`);
      // console.log(Math.round(x / slideWidth) * slideWidth);
      // We need to know which way things are dragged here to make it snap sooner
      // bit we need to know direction to know which way to be biased to. Bias is based on slide width
      const bias = slideWidth / 3;

      if (lastDragDirection === 'left') {
        x -= bias;
      }
      if (lastDragDirection === 'right') {
        x += bias;
      }

      return Math.round(x / slideWidth) * slideWidth;
    }

    const tlgradient = gsap
      .timeline({
        scrollTrigger: {
          trigger: '.block-title-xl',
          start: 'top 100%',
          end: 'top 50%',
          scrub: true,
          markers: false,
        },
      })
      .to('.block-title-xl', {
        backgroundImage:
          'linear-gradient(45deg, var(--bg) -100%, var(--grhighlight) 50%, var(--bg) 100%)',
        duration: 1,
        ease: 'none',
      });

    // The content elements
    const contentItems = [];
    [...document.querySelectorAll('.content__item')].forEach((item) =>
      contentItems.push(new ContentItem(item))
    );

    // The images strip
    const strip = new Strip(
      document.querySelector('.strip-outer'),
      contentItems
    );

    const { body } = document;
    const docEl = document.documentElement;

    const MathUtils = {
      lineEq: (y2, y1, x2, x1, currentVal) => {
        // y = mx + b

        const m = (y2 - y1) / (x2 - x1);

        const b = y1 - m * x1;

        return m * currentVal + b;
      },
      lerp: (a, b, n) => (1 - n) * a + n * b,
      distance: (x1, x2, y1, y2) => {
        const a = x1 - x2;
        const b = y1 - y2;
        return Math.hypot(a, b);
      },
    };

    let winsize;
    const calcWinsize = () =>
      (winsize = { width: window.innerWidth, height: window.innerHeight });
    calcWinsize();
    window.addEventListener('resize', calcWinsize);

    const getMousePos = (ev) => {
      let posx = 0;
      let posy = 0;
      if (!ev) ev = window.event;
      if (ev.pageX || ev.pageY) {
        posx = ev.pageX;
        posy = ev.pageY;
      } else if (ev.clientX || ev.clientY) {
        posx = ev.clientX + body.scrollLeft + docEl.scrollLeft;
        posy = ev.clientY + body.scrollTop + docEl.scrollTop;
      }
      return { x: posx, y: posy };
    };

    let mousePos = { x: winsize.width / 2, y: winsize.height / 2 };
    window.addEventListener('mousemove', (ev) => (mousePos = getMousePos(ev)));

    // const imgs = [...document.querySelectorAll('.content__img')];

    const imgs = workPreview;
    const imgsTotal = imgs.length;
    const imgTranslations = [...new Array(imgsTotal)].map(() => ({
      x: 0,
      y: 0,
    }));
  }, []);

  function handleMouse(e) {
    // this.setState({ mouseX: e.screenX, mouseY: e.screenY });
    // console.log("test eee" + e.screenY)
    // console.log("workPreview laius" + workPreview.clientWidth)
    const $this = workPreview;

    // var $this = $('#container');
    const relX = e.screenX - workPreview.offsetLeft;
    const relY = e.screenY - workPreview.offsetTop;

    gsap.to(workPreview, {
      duration: 1,
      x: ((relX - workPreview.clientWidth / 2) / workPreview.clientWidth) * 320,
      y:
        ((relY - workPreview.clientHeight / 2) / workPreview.clientHeight) *
        320,
    });
  }

  function mouseOutTest({ pageX: x, pageY: y }) {
    gsap.to(workPreview, {
      duration: 1,
      x: 0,
      y: 0,
    });
  }

  function mouseOverTest({ pageX: x, pageY: y }) {
    // console.log(x, y);

    gsap.to(workPreview, {
      duration: 1,
      // x: (relX - $this.width()/2) / $this.width() * movement,
      // y: (relY - $this.height()/2) / $this.height() * movement,
      x,
      y,
    });
  }

  function filterProjects(input, sliderRow) {
    const list = [];

    for (const i in input) {
      if (input[i].sliderRow == sliderRow) {
        list.push(input[i]);
      }
    }

    return list;
  }

  const projectsRow1 = filterProjects(GiveProjectsData, 1);
  const projectsRow2 = filterProjects(GiveProjectsData, 2);
  // const allProjects = GiveProjectsData;

  /*
import wachtlokaal from '../../assets/homepageprojects/';
import tekto from '../../assets/homepageprojects/';

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: '',
  image: wachtlokaal,
  sliderRow: 1,
});
pr++;

GiveProjectsData.push({
  id: `pr-${pr}`,
  title: '',
  image: tekto,
  sliderRow: 1,
});

*/
  return (
    <div className="portfolio-block block3">
      <div className="block-title-xl">
     

        <GiveContentTrans language="et">
        Valitud projektid mida oleme&nbsp;teinud
      </GiveContentTrans>
      <GiveContentTrans language="en">
      Some of the past projects we have worked&nbsp;with
      </GiveContentTrans>
      </div>
      <main className="strip-outer">
        <div className="slides-container">
          <div className="slides-inner strip">
            <div className="slide" key="pr-1">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/readyplayerme.jpg"
                  title="Ready Player Me"
                  alt="Ready Player Me kodulehekülje kujundus"
                  layout="fullWidth"
                />
                {/*
                <video
                  width="100%"
                  poster={project.image}
                  loop
                  muted
                  // autoPlay
                >
                  <source src={project.video} type="video/mp4" />
                  Your browser doesn't support HTML5 video tag.
                </video>
                * */}
              </div>
            </div>

            <div className="slide" key="pr-2">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/readyplayerme2.jpg"
                  title="Ready Player Me"
                  alt="Ready Player Me logo kujundus"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-3">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/cedaradashboard.jpg"
                  title="Cedara"
                  alt="Cedara SaaS töölaua UI/UX disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-4">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/omatalo.jpg"
                  title="Omatalo"
                  alt="Omatalo kodulehekülje disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-5">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/omatalo2.jpg"
                  title="Omatalo"
                  alt="Omatalo kodulehekülje disaini vaated"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-6">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/documental.jpg"
                  title="Documental"
                  alt="Documental enesehindamis äppi UI/UX disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-7">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/carloan.jpg"
                  title="Credit24 Carloan"
                  alt="Credit24 Carloan OOH reklaam"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-8">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/superforest1.jpg"
                  title="Superforest"
                  alt="Superforest mobiilse veebirakenduse UI/UX disaini avavaade"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-9">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/superforest2.jpg"
                  title="Superforest"
                  alt="Superforest mobiilse veebirakenduse UI/UX disain"
                  layout="fullWidth"
                />
              </div>
            </div>
            <div className="slide" key="pr-10">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/superforest3.jpg"
                  title="Superforest"
                  alt="Superforest kampaania töölaua UI/UX disain"
                  layout="fullWidth"
                />
              </div>
            </div>
            <div className="slide" key="pr-11">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/superforest4.jpg"
                  title="Superforest"
                  alt="Superforest kodulehekülje (Wordpress theme) disain ja arendus"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-12">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/credinord.jpg"
                  title="Credinord"
                  alt="Credinord - skandinaavia fintech kodulehekülje disain"
                  layout="fullWidth"
                />
              </div>
            </div>
            <div className="slide" key="pr-13">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/arvuta.jpg"
                  title="Arvuta"
                  alt="Arvuta mobiilirakenduse UI/UX disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-14">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/creditea.jpg"
                  title="Creditea"
                  alt="Creditea kodulehekülje kujunduskavand"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-15">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/noortejooks.jpg"
                  title="Hepa noortejooks"
                  alt="Hepa noortejooks t-särgi kujundus"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-16">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/aegarstile.jpg"
                  title="Aegarstile"
                  alt="Aegarstile meditsiiniportaali UI/UX disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-17">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/punks.jpg"
                  title="ReadyPlayerMe Punks"
                  alt="ReadyPlayerMe Punks kampaanialehe UI/UX kujundus"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-18">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/c24.jpg"
                  title="Credit24 Finland"
                  alt="Credit24 Soome kodulehekülje disainikavand"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-19">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/contentkitchen.jpg"
                  title="Contentkitchen"
                  alt="Contentkitchen SaaS töölaua UI/UX disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-20">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/bookedit.jpg"
                  title="Bookedit"
                  alt="Bookedit kodulehe disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-21">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/rahapalvelu.jpg"
                  title="Rahapalvelu"
                  alt="Rahapalvelu kodulehe disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-22">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/turva.jpg"
                  title="Turva"
                  alt="Turva logo kujundus ja brändiraamat"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-23">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/eventmanager.jpg"
                  title="Licklist eventmanager"
                  alt="Licklist sündmuskorraldus mobiiliäppi UI/UX disain"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-24">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/taw1.jpg"
                  title="Tallinn Art Week"
                  alt="Tallinn Art Week kodulehekülje kujundus"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-25">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/taw12.jpg"
                  title="Tallinn Art Week"
                  alt="Tallinn Art Week reklaamplakat"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-26">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/taw2.jpg"
                  title="Tallinn Art Week"
                  alt="Tallinn Art Week kodulehekülje kujundus"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-27">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/taw22.jpg"
                  title="Tallinn Art Week"
                  alt="Tallinn Art Week poster"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-28">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/taw3.jpg"
                  title="Tallinn Art Week"
                  alt="Tallinn Art Week veebikujundus"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-29">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/taw32.jpg"
                  title="Tallinn Art Week"
                  alt="Tallinn Art Week plakat"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-30">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/wachtlokaal.jpg"
                  title="Wachtlokaal"
                  alt="Wachtlokaal - restorani kodulehekülje kujundus"
                  layout="fullWidth"
                />
              </div>
            </div>

            <div className="slide" key="pr-31">
              <div className="img-outer img-outer--content">
                <StaticImage
                  src="../../assets/homepageprojects/tekto.jpg"
                  title="Tekto"
                  alt="Tekto e-poe veebikujundus"
                  layout="fullWidth"
                />
              </div>
            </div>
          </div>
        </div>

        {/*         <div className="controls">
          <button id="prevButton">Prev</button>
          <button id="nextButton">Next</button>
        </div> */}
      </main>
    </div>
  );
}


// Content Item
class ContentItem {
  constructor(el) {
    this.DOM = { el };
    this.DOM.image = this.DOM.el.querySelector('.img-outer');
    // this.DOM.title = this.DOM.el.querySelector('.content__item-title');
    // this.DOM.text = this.DOM.el.querySelector('.content__item-text');
  }
}

export default ContentItem;

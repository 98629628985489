
import { gsap } from 'gsap';

// Strip Item
class StripItem {
  constructor(el) {
    this.DOM = { el };
    // this.DOM.image = this.DOM.el.querySelector('.img-inner');
    this.DOM.label = this.DOM.el.querySelector('.strip__item-link');

    this.initEvents();
  }

  initEvents() {
    // Hovering the number makes it slide out/in
    this.DOM.label.addEventListener('mouseenter', () => {
      const inner = this.DOM.label.querySelector('span');
      const others = this.DOM.label.parentNode.parentNode;
      // const innerImg = this.DOM.number.querySelector('.img-inner');

      // inner.classList.add('current');

      new gsap.timeline()
        .to(
          others,
          {
            ease: 'Quad.easeOut',
            duration: 0.2,
            alpha: 0.9,
          },
          0
        )
        .to(
          inner,
          {
            ease: 'Quad.easeOut',
            duration: 0.2,
            color: '#fff',
            scale: 1.1,
          },
          0
        );
    });

    this.DOM.label.addEventListener('mouseleave', () => {
      const inner = this.DOM.label.querySelector('span');
      const others = this.DOM.label.parentNode.parentNode;

      inner.classList.remove('current');

      gsap.killTweensOf(inner);
      gsap.set(inner, {
        scale: 1,
        color: 'transparent',
        opacity: 1,
      });
      gsap.to(
        others,
        {
          ease: 'Quad.easeOut',
          duration: 0.2,

          alpha: 1,
          scale: 1,
        },
        0
      );
    });
  }
}

export default StripItem;